<template>
  <Modal v-model="editStatus" class-name="editreward" title="提成管理" width="400" @on-ok="commitData" @on-visible-change="close" :loading="subLoading" :closable="false" :mask-closable="false">
    <Table size="small" :columns="columns" :data="data" :loading="table_loading">
      <template slot="paltform">{{paltform}}</template>
      <template slot-scope="row" slot="rate">
        <InputNumber style="width: 100%;" :active-change="false" :precision="1" :max="100" :min="0" v-model="data[row.index].rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')"></InputNumber>
      </template>
    </Table>
  </Modal>
</template>
<script>

export default {
  name: 'editReward',
  data(){
    return {
      editStatus:false,
      subLoading:true,
      table_loading:false,
      pid:0,
      paltform:'',
      columns:[
        {title: '平台名称',slot: 'paltform',minWidth:120},
        {title: '等级',key: 'level_name',minWidth:120},
        {title: '提成比例',slot: 'rate',minWidth:100},
      ],
      data:[]
    }
  },
  methods:{
    open:function (id,paltform){
      var _this=this;
      this.pid=id;
      this.paltform=paltform;
      this.editStatus=true;
      this.table_loading=true;
      this.subLoading=true;
      _this.requestApi('/adm/get_reward.html',{'pid':this.pid}).then(function (res){
        _this.table_loading=false;
        if(res.status==1){
          _this.data=res.data;
        }
      })
    },
    /**
     * 提交保存
     */
    commitData:function(){
      var _this = this;
      _this.requestApi('/adm/save_reward.html', {pid:this.pid,data:this.data}).then(function (res){
        if(res.status == 1){
          _this.tipSuccess(res.msg);
          _this.editStatus = false;
        }else{
          _this.subLoading = false;
          setTimeout(function (){_this.subLoading = true;},1);
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 关闭 modal
     */
    close:function(status){
      if(!status){
        this.data=[];
      }
    }
  }
}
</script>
<style lang="less">
</style>