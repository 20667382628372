<template>
  <Modal v-model="editStatus" class-name="editplatform" :title="data.id>0?'修改平台':'新增平台'" width="300" @on-ok="commitData" @on-visible-change="close" :loading="subLoading" :closable="false" :mask-closable="false">
    <Form label-position="left" :label-width="70" ref="fValidate" :model="data" :rules="ruleValid">
      <FormItem label="平台名称" style="margin-bottom: 10px" prop="platform">
        <Input type="text" v-model="data.platform" maxlength="30"></Input>
      </FormItem>
      <FormItem :label="'上'+(k>0?k+1:'')+'级提成'" style="margin-bottom: 10px" v-for="(p,k) in data.ratearr" :key="k" prop="rate">
        <InputNumber style="width: 160px;" :active-change="false" :precision="1" :max="100" :min="0" v-model="data.ratearr[k]" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')"></InputNumber>
        <a href="javascript:void(0)" class="delrate" @click="delrate(k)">删除</a>
      </FormItem>
      <FormItem label="" style="margin-bottom: 10px">
        <Button type="info" size="small" @click="addrate">添加提成</Button>
      </FormItem>
      <FormItem label="奖励类型" style="margin-bottom: 10px">
        <Input type="text" v-model="data.rtype" maxlength="60" placeholder="用竖线“|”分隔"></Input>
      </FormItem>
      <FormItem label="状态" style="margin-bottom: 10px" prop="status">
        <i-switch size="large" v-model="data.status" :true-value="1" :false-value="0">
          <span slot="open">正常</span>
          <span slot="close">冻结</span>
        </i-switch>
      </FormItem>
    </Form>
  </Modal>
</template>
<script>

export default {
  name: 'editPlatform',
  data(){
    return {
      editStatus:false,
      subLoading:true,
      data:{id:0,platform:'',status:1,ratearr:[]},
      ruleValid:{
        platform: [{ required: true, message: '平台名称必填', trigger: 'blur' }],
        status: [{ required: true, type: 'number', message: '', trigger: 'change' }],
      },
    }
  },
  methods:{
    open:function (data){
      this.editStatus = true;
      this.subLoading = true;
      this.$refs.fValidate.resetFields();
      this.data=JSON.parse(JSON.stringify(data));
    },
    /**
     * 提交保存
     */
    commitData:function(){
      var _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          _this.requestApi('/adm/save_platform.html', this.data).then(function (res){
            if(res.status == 1){
              _this.tipSuccess(res.msg);
              _this.$emit('saveOk');
              _this.editStatus = false;
            }else{
              _this.subLoading = false;
              setTimeout(function (){_this.subLoading = true;},1);
              _this.alertError(res.msg);
            }
          })
        }else{
          _this.subLoading = false;
          setTimeout(function (){_this.subLoading = true;},1);
        }
      })
    },
    /**
     * 删除提成
     */
    delrate:function (k){
      this.data.ratearr.splice(k,1);
    },
    /**
     * 添加提成
     */
    addrate:function (){
      if(this.data.ratearr.length>=8) return this.alertError('限制8级！');
      this.data.ratearr.push(0);
    },
    /**
     * 关闭 modal
     */
    close:function(status){
      if(!status){
        this.data={id:0,platform:'',status:1,ratearr:[]};
      }
    }
  }
}
</script>
<style lang="less">
.editplatform{
  .delrate{
    display: inline-block; float: right; font-size: 12px;
  }
}
</style>