<template>
  <div class="platform">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>提成配置</BreadcrumbItem>
          <BreadcrumbItem>平台管理</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div class="right">
        <Button type="info" icon="ios-add" @click="addPlatform()">添加平台</Button>
      </div>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
      <template slot-scope="row" slot="rtypearr">
        <span class="rtype" v-for="(p,k) in row.row.rtypearr" :key="k">{{p}}</span>
      </template>
      <template slot-scope="row" slot="status">
        <span :class="'pstatus'+row.row.status">{{status[row.row.status]}}</span>
      </template>
      <template slot-scope="row" slot="action">
        <Button size="small" type="info" @click="editPlatform(data[row.index])">编辑</Button>
        <Button size="small" type="primary" style="margin-left: 10px;" @click="showReward(row.row.id,row.row.platform)">计业绩提成</Button>
      </template>
    </Table>
    <edit-platform ref="editPlatform" @saveOk="init"></edit-platform>
    <edit-reward ref="editReward"></edit-reward>
  </div>
</template>
<script>
import EditPlatform from "./edit_platform";
import EditReward from "./edit_reward";
export default {
  name: 'Platform',
  components: {EditReward, EditPlatform},
  data(){
    return {
      columns:[
        {title: 'ID',key: 'id',minWidth:80},
        {title: '平台名称',key: 'platform',minWidth:80},
        {title: '不计业绩提成(%)',key: 'rate',minWidth:260},
        {title: '奖励类型',slot: 'rtypearr',minWidth:200},
        {title: '状态', slot: 'status',minWidth:80},
        {title: '操作',slot:'action',width:200,align:'center'}
      ],
      data:[],
      status:{0:'禁用',1:'正常'},
      loading:false
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.loading=true;
      this.requestApi('/adm/platform', {page:this.page,page_size:this.pageSize}).then(function (res){
        if(res.status==1) _this.data=res.data;
        _this.loading=false;
      })
    },
    /**
     * 提成管理
     */
    showReward:function (id,platform){
      this.$refs.editReward.open(id,platform);
    },
    /**
     * 修改平台
     */
    editPlatform:function (data){
      this.$refs.editPlatform.open(data);
    },
    /**
     * 添加平台
     */
    addPlatform:function (){
      this.$refs.editPlatform.open({id:0,platform:'',status:1});
    }
  }
}
</script>
<style lang="less">
.platform{
  .pstatus0{color: #ff0000;}
  .rtype{display: inline-block; height: 22px; padding: 0 6px; margin: 5px 6px 5px 0; font-size: 12px; line-height: 22px; border-radius: 4px; background: #f1f1f1;}
}
</style>